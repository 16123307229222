<!-- Simple-calculator.component.html -->
  
<app-base-calculator #baseCalculator [title]="'Simple Calculator'">
  <button class="button" (click)="baseCalculator.clear()">C</button>
  <button class="button" (click)="baseCalculator.backspace()">←</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">+</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">-</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">×</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">÷</button>
  <button class="button" (click)="baseCalculator.equals()">=</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">7</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">8</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">9</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">4</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">5</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">6</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">1</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">2</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">3</button>
  <button class="button" (click)="baseCalculator.AddValue($event)">0</button>
  <button class="button" (click)="baseCalculator.decimal()">.</button>
</app-base-calculator>