/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */
import { Tab } from './tab.js';
/**
 * A secondary tab component.
 */
export class SecondaryTab extends Tab {
  constructor() {
    super(...arguments);
    this.fullWidthIndicator = true;
  }
}
