<div class="project-card" [class.border]="!project.images">
  @if(project.images)
  {
    <div class="image-container">
      @for (image of project.images; track $index) {
        <img 
        [src]="image" 
        [alt]="'Project image ' + ($index + 1)" 
        class="project-image fade" 
        [ngStyle]="{'animation-delay': getAnimationDelay($index), 'animation-duration': getAnimationDuration()}" />
      }
    </div>
  }
  
  <div class="project-info" [class.overlay]="project.images && project.images.length > 0" >
    <h2>{{ project.title }}</h2>
    <p>{{ project.description }}</p>
    <a [routerLink]="['/Project', project.id]">Learn More</a>
  </div>
</div>
