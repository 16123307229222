<div class="footer">
    <div class="footer-content">
      <div class="social-icons">
        <a href="{{commonDataService.CommonData?.Github_URL}}" target="_blank" aria-label="GitHub">
          <i class="bi-github"></i>
        </a>
        <a href="{{commonDataService.CommonData?.LinkedIn_URL}}" target="_blank" aria-label="LinkedIn">
          <i class="bi-linkedin"></i>
        </a>
        <a href="mailto:{{commonDataService.CommonData?.Contact?.Email}}" aria-label="Email">
          <i class="bi-envelope"></i>
        </a>
      </div>
      <p>© {{ currentYear }} {{ commonDataService.CommonData?.FullName }}. All rights reserved.</p>
    </div>
  </div>
  