<!-- base-calculator.component.html -->
<div class="calculator">
    <h1>{{title}}</h1>

    <input #expressionInput (keydown)="handleKeyPress($event)" [formControl]="displayControl" placeholder="Enter expression" class="expression-input" />

    @if (error !== '') {
    <!-- Readonly Temporary Result Display -->
    <h2 type="text" class="temporary-result error">{{error}}</h2>
    }
    @else if (temporaryResult !== '') {
    <!-- Readonly Temporary Result Display -->
    <h2 type="text" class="temporary-result">{{temporaryResult}}</h2>
    }

    <div class="buttons">
        <ng-content></ng-content> <!-- Buttons from child components will be injected here -->
    </div>

    @if (history.length !== 0) {
    <!-- History Section -->
    <div class="history">
        <h3>History</h3>
        <ul class="history-list">
            @for (item of history; track $index) {
            <li (click)="loadFromHistory(item)" class="history-item">
                {{ item.Equation }} = {{item.Result}}
            </li>
            }
        </ul>
    </div>
    }
</div>