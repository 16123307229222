<div class="calculator">
  <div class="display">
    <input type="text" [(ngModel)]="currentValue.value" readonly />
  </div>

  <div class="modes">
  @for (valueMode of values; track $index)
  {
    <div class="mode" (click)="switchMode(valueMode.mode)">
      <a class="title" [text]="valueMode.title"></a>
      <a class="value" [text]="valueMode.value"></a>
    </div>
  }
  </div>

  <div class="grid">
    <!-- Hexadecimal digits (A-F) will be disabled when the current base is not HEX -->
    <button (click)="pressKey('A')" [disabled]="currentValue.mode <= 10">A</button>
    <button (click)="pressKey('B')" [disabled]="currentValue.mode <= 11">B</button>
    <button (click)="pressKey('C')" [disabled]="currentValue.mode <= 12">C</button>
    <button (click)="pressKey('D')" [disabled]="currentValue.mode <= 13">D</button>
    <button (click)="pressKey('E')" [disabled]="currentValue.mode <= 14">E</button>
    <button (click)="pressKey('F')" [disabled]="currentValue.mode <= 15">F</button>

    <!-- Number digits and operators -->
    <button (click)="pressKey('7')" [disabled]="currentValue.mode <= 7">7</button>
    <button (click)="pressKey('8')" [disabled]="currentValue.mode <= 8">8</button>
    <button (click)="pressKey('9')" [disabled]="currentValue.mode <= 9">9</button>
    <button (click)="pressKey('/')">÷</button>

    <button (click)="pressKey('4')" [disabled]="currentValue.mode <= 4">4</button>
    <button (click)="pressKey('5')" [disabled]="currentValue.mode <= 5">5</button>
    <button (click)="pressKey('6')" [disabled]="currentValue.mode <= 6">6</button>
    <button (click)="pressKey('*')">×</button>

    <button (click)="pressKey('1')">1</button>
    <button (click)="pressKey('2')" [disabled]="currentValue.mode <= 2">2</button>
    <button (click)="pressKey('3')" [disabled]="currentValue.mode <= 3">3</button>
    <button (click)="pressKey('-')">−</button>

    <button (click)="pressKey('0')">0</button>
    <button (click)="clearDisplay()">C</button>
    <button (click)="calculate()">=</button>
    <button (click)="pressKey('+')">+</button>
  </div>
</div>
