/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */
import { __decorate } from "tslib";
import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Progress } from './progress.js';
/**
 * A linear progress component.
 */
export class LinearProgress extends Progress {
  constructor() {
    super(...arguments);
    /**
     * Buffer amount to display, a fraction between 0 and `max`.
     * If the value is 0 or negative, the buffer is not displayed.
     */
    this.buffer = 0;
  }
  // Note, the indeterminate animation is rendered with transform %'s
  // Previously, this was optimized to use px calculated with the resizeObserver
  // due to a now fixed Chrome bug: crbug.com/389359.
  renderIndicator() {
    const progressStyles = {
      transform: `scaleX(${(this.indeterminate ? 1 : this.value / this.max) * 100}%)`
    };
    const bufferValue = this.buffer ?? 0;
    const hasBuffer = bufferValue > 0;
    const dotSize = this.indeterminate || !hasBuffer ? 1 : bufferValue / this.max;
    const dotStyles = {
      transform: `scaleX(${dotSize * 100}%)`
    };
    // Only display dots when visible - this prevents invisible infinite
    // animation.
    const hideDots = this.indeterminate || !hasBuffer || bufferValue >= this.max || this.value >= this.max;
    return html`
      <div class="dots" ?hidden=${hideDots}></div>
      <div class="inactive-track" style=${styleMap(dotStyles)}></div>
      <div class="bar primary-bar" style=${styleMap(progressStyles)}>
        <div class="bar-inner"></div>
      </div>
      <div class="bar secondary-bar">
        <div class="bar-inner"></div>
      </div>
    `;
  }
}
__decorate([property({
  type: Number
})], LinearProgress.prototype, "buffer", void 0);
