/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */
import { isServer } from 'lit';
import { internals } from '../../labs/behaviors/element-internals.js';
/**
 * Sets up an element's constructor to enable form submission. The element
 * instance should be form associated and have a `type` property.
 *
 * A click listener is added to each element instance. If the click is not
 * default prevented, it will submit the element's form, if any.
 *
 * @example
 * ```ts
 * class MyElement extends mixinElementInternals(LitElement) {
 *   static {
 *     setupFormSubmitter(MyElement);
 *   }
 *
 *   static formAssociated = true;
 *
 *   type: FormSubmitterType = 'submit';
 * }
 * ```
 *
 * @param ctor The form submitter element's constructor.
 */
export function setupFormSubmitter(ctor) {
  if (isServer) {
    return;
  }
  ctor.addInitializer(instance => {
    const submitter = instance;
    submitter.addEventListener('click', async event => {
      const {
        type,
        [internals]: elementInternals
      } = submitter;
      const {
        form
      } = elementInternals;
      if (!form || type === 'button') {
        return;
      }
      // Wait a full task for event bubbling to complete.
      await new Promise(resolve => {
        setTimeout(resolve);
      });
      if (event.defaultPrevented) {
        return;
      }
      if (type === 'reset') {
        form.reset();
        return;
      }
      // form.requestSubmit(submitter) does not work with form associated custom
      // elements. This patches the dispatched submit event to add the correct
      // `submitter`.
      // See https://github.com/WICG/webcomponents/issues/814
      form.addEventListener('submit', submitEvent => {
        Object.defineProperty(submitEvent, 'submitter', {
          configurable: true,
          enumerable: true,
          get: () => submitter
        });
      }, {
        capture: true,
        once: true
      });
      elementInternals.setFormValue(submitter.value);
      form.requestSubmit();
    });
  });
}
