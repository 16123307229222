<div class="calculator-page">
    <div class="sidebar">
      <button (click)="switchCalculator('simple')" class="sidebar-button">Simple</button>
      <button (click)="switchCalculator('science')" class="sidebar-button">Science</button>
      <button (click)="switchCalculator('programming')" class="sidebar-button">Programming</button>
    </div>
  
    <div class="calculator-container">
        @if(selectedCalculator === 'simple')
        {
            <ng-container>
                <app-simple-calculator></app-simple-calculator>
              </ng-container>
        }
        @else if (selectedCalculator === 'science') 
        {
            <ng-container>
                <app-science-calculator></app-science-calculator>
              </ng-container>
        }
        @else if (selectedCalculator === 'programming') 
        {
            <ng-container>
              <app-programming-calculator></app-programming-calculator>
            </ng-container>
        }
    </div>
  </div>
  